

.navbar1 {
    position: fixed;
    top: 0;
    left: 0;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 100px;
    padding-right: 50px;
    background-color: white;
    box-shadow: 0px 2px 10px lightgrey;
    width: 100vw;
    padding-top: 15px;
}

@import url('https://cdn.syncfusion.com/ej2/material.css');

.dashboard-container {
    margin-left: 10%;
    width: 80%;
    background-color: white;
    border-radius: 10px;
    padding: 30px;
}

.title {
    width: 80%;
    margin-left: 10%;
    text-align: left;
    margin-bottom: 20px;
}

.add-question-btn {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px;
    justify-content: center;
    align-items: center;
    color: grey;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 20px;
    background-color: #DEDEDE4D;
}

.backview {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: scroll;
}

.context-menu {
    width: 250px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 2px 10px darkgrey;
}

.add-question-btn:hover {
    background-color: #DEDEDE;
}

.btn-container {
    padding-top: 5px;
    overflow: visible;
}

.main-btn {
    border-radius: 10px;
    background-color: #007bff;
    color: white;
    padding-top: 8px;
    min-height: 35px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: bold;
    justify-content: center;
    text-align: center;
    align-items: center;
    transition: all .5s ease-in-out;
    box-shadow: 0px 2px 10px lightgrey;
    cursor: pointer;
}

.main-btn:hover {
    transform: translateY(-3px);
}

.question-selection {
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: white;
}

.question-selection:hover {
    background-color: lightgrey;
    cursor: pointer;
}

.question-container {
    background-color: #DEDEDE4D;
    border-radius: 10px;
    padding: 30px;
    width: 100%;
    margin-bottom: 15px;
    padding-bottom: 70px;
    position: relative;
}

.answer-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.answer {
    background-color: white;
    border-radius: 5px;
    color: #818181;
    justify-content: center;
    align-items: center;
    width: 80%;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 5px;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: 0px 2px 10px lightgrey;
}

.answer-selectable:hover {
    background-color: white !important; 
    cursor: default !important;
}

.answer:hover {
    cursor: pointer;
    background-color: lightgrey;
}

.custom-input {
    border: 0;
    border-bottom: 1px solid lightgrey;
    padding-left: 10px; 
    padding-right: 10px; 
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: transparent;
    color: #646464;
}

/*// STATS //*/

.stats-container {
    background-color: #F4F4F4;
    border-radius: 10px;
    padding: 30px;
    color: #666666;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 10px lightgrey;
}

/*// ADMIN LOGIN //*/

.login-container {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 2px 10px lightgrey;
    width: 40%;
    min-height: 250px;
    max-height: 80%; 
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

/*// ADMIN DASHBOARD //*/

.with-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.half {
    width: 70%;
    align-self: flex-start;
}

.with-background {
    background-color: white;
    border-radius: 10px;
    margin-left: 15px;
    padding: 15px;
    margin-bottom: 10px;
}

.side-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.horizontal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.horizontal-s {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 100%;
}

.reg-input {
    border: 1px solid lightgrey;
    border-radius: 10px;
    padding: 15px;
    color: grey;
    min-width: 300px;
}

.mySelect__value-container {
    height: 50px;
    width: 300px;
    color: #525252;
}

.second {
    margin-left: 10px;
    margin-top: 0px;
}

.info-insegnante {
    min-height: 100px;
    width: 100%;
    padding: 20px;
}

/*// STUDENTI DASHBOARD //*/

.test-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: grey;
    background-color: #F3F3F3;
    box-shadow: 0px 2px 5px lightgrey;
    padding: 20px;
    padding-bottom: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.test-btn:hover {
    cursor: pointer;
    background-color: #E9E9E9;
}

.arrow-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.arrow-btn p {
    display: block;
    color: #258299;
    font-weight: bold;
    margin-top: 5px
}

.info-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.info-div p {
    margin-left: 20px;
}

.text-container {
    padding: 20px;
    display: flex;
    text-align: left;
    padding-top: 35px;
    align-items: center;
    color: grey;
    width: 100%;
    height: 100px;
    border: 1px solid #EEEEEE;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-bottom: -1px;
}

.texttext-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.answer-text {
    padding: 20px;
    text-align: center;
    color: grey;
    font-weight: bold;
    width: 100%;
    max-width: 200px;
    height: 100px;
    overflow-x: hidden;
    overflow-y: scroll;
    border-radius: 10px;
    background-color: #D8D8D8;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.cloze-select {
    min-width: 50px;
    max-width: 100px;
    max-height: 20px !important;
    font-size: 12px;
    font-weight: bold;
    color: #6B6B6B;
    color: grey;
    border: 0px;
    border-bottom: 1px solid lightgrey;
}

.order-container {
    width: 80%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 2px 10px lightgrey;
    margin-bottom: 10px;
}

.over-view {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 2px 10px darkgrey;
    padding: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
    margin-left: 100px;
    margin-right: 100px;
}

.risultato {
    height: 200px;
    padding-top: 50px;
}

.risultato-container {
    width: 220px;
    height: 220px;
    border-radius: 110px;
    margin-left: calc((100% - 220px)/2);
    margin-top: -80px;
    background-color: #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
}

.risultato-view {
    width: 200px;
    height: 200px;
    background-color: white;
    border-radius: 100px;
    box-shadow: 0px 2px 10px lightgrey;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-container-risultato {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: -140px;
    margin-left: 10%;
    margin-right: 10%;
}

.btn-left {
    width: 100%;
    padding-top: 30px;
}

.pdf-btn {
    background-color: white;
    font-weight: bold;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: 200px;
    box-shadow: 0px 2px 10px lightgrey;
    cursor: pointer;
}

.pdf-btn:hover {
    cursor: pointer;
    background-color: #F5F5F5;
}

.risposta-view {
    background-color: #FFFFFF80;
    box-shadow: 0px 2px 10px lightgrey;
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 10px;
    margin-left: 10%;
    margin-right: 10%;
}

.mobile-padding {
    margin-top: 100px;
    padding-bottom: 100px;
}

.margin {
    margin-left: 10%;
    margin-right: 10%;
}


@media (max-width: 992px) {

    .btn-container-risultato {
        margin-left: 2.5%;
        margin-right: 2.5%;
    }

    .with-btn {
        flex-direction: column;
    }
    
    .dashboard-container {
        width: 95%;
        margin-left: 2.5%;
    }

    .title {
        width: 95%;
        margin-left: 2.5%;
    }

    .custom-input {
        width: 100% !important;
    }

    .login-container {
        width: 80%;
    }

    .margin {
        margin-left: 2.5%;
        margin-right: 2.5%;
    }

    .risposta-view {
        margin-left: 2.5%;
        margin-right: 2.5%;
    }

}

@media (max-width: 767px) {

    .risultato {
        height: 250px;
    }

    .risultato-container {
        width: 180px;
        height: 180px;
        border-radius: 90px;
        margin-left: calc((100% - 180px)/2);
        margin-top: -50px;
    }

    .risultato-view {
        width: 160px;
        height: 160px;
        border-radius: 80px;
    }

    .btn-container-risultato {
        margin-top: 0px;
        flex-direction: column;
        margin-left: 0px;
        margin-right: 0px;
    }

    .pdf-btn {
        width: 100%;
    }

    .mobile-padding {
        margin-top: 10px;
    }

    .margin {
        margin-left: 0px;
        margin-right: 0px;
    }

    .context-menu {
        left: 2.5% !important;
        width: 95% !important;
    }

    .risposta-view {
        margin-left: 0px;
        margin-right: 0px;
    }

    .order-container {
        width: 100%;
    }

    .texttext-container {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .stats-container {
        flex-direction: column;
    }

    .login-container {
        width: 95%;
    }

    .half {
        width: 100%;
        margin-left: 0px;
    }

    .horizontal {
        flex-direction: column;
    }

    .horizontal-s {
        flex-direction: column;
    }

    .second {
        margin-left: 0px;
        margin-top: 10px;
    }

    .arrow-btn p {
        display: none;
    }

    .test-btn h5 {
        max-width: 200px;
    }

    .info-img {
        display: none;
    }

    .question-container {
        padding: 10px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .dashboard-container {
        width: 100%;
        margin-left: 0px;
    }

    .over-view {
        flex-direction: column;
        margin-top: 200px;
        margin-left: 30px;
        margin-right: 30px;
    }
}

